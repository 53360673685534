<template>
  <div class="rounded-md lb-shadow xl:col-span-2 md:overflow-hidden relative">
    <h3 class="flex items-center text-lg font-semibold relative bg-white px-6 py-4">
      {{ trans('leakblock.leakOverview.title') }}
    </h3>
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
    </div>
    <template v-else>
      <div class="px-3 md:px-6 pt-3 pb-[75px] md:pb-[80px]" ref="osComponentMaxScrollHeight" data-overlayscrollbars-initialize>
        <div v-if="leaks.length > 0" class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <template v-for="leak in leaks" :key="leak.id">
            <router-link
                :to="{ name: 'leakDetail', params: { lang: $route.params.lang, accountUrl: $route.params.accountUrl, leakId: String(leak.leak_id) } }"
                class="rounded-full transition duration-300 hover:scale-[1.02]"
            >
              <div class="intro-y bg-white rounded-md p-4 shadow-sm border border-gray-200 relative">
                <!-- Main content section -->
                <div class="flex-grow min-w-0">
                  <!-- Website header with status -->
                  <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-2">
                    <div class="flex items-center mb-2 sm:mb-0">
                      <img
                          :src="`https://www.google.com/s2/favicons?domain=${encodeURIComponent(leak.link)}`"
                          :alt="`${leak.website} favicon`"
                          class="w-4 h-4 mr-2 flex-shrink-0"
                          @error="handleFaviconError"
                      />
                      <h3 class="font-medium text-lg text-gray-900 break-words">{{ leak.website }}</h3>
                    </div>
                    <!-- Status badge -->
                    <span class="text-xs font-medium px-2 py-1 rounded-full self-start sm:self-center w-fit"
                          :style="{ backgroundColor: leak.leak_status.bgColor, color: leak.leak_status.color }">
                      {{ trans(leak.leak_status.name) }}
                    </span>
                  </div>

                  <!-- Search engine info -->
                  <div v-if="leak.search_engine" class="flex items-center mb-2 text-gray-500 text-sm flex-wrap">
                    <span class="mr-1">{{ trans('leakblock.lastLeaks.foundThrough') }}</span>
                    <div class="flex items-center">
                      <img
                          :src="leak.search_engine.favicon"
                          :alt="`${leak.website} favicon`"
                          class="w-4 h-4 mx-1 flex-shrink-0"
                      />
                      <span>{{ leak.search_engine.name }}</span>
                    </div>
                  </div>

                  <!-- Link -->
                  <div v-else class="text-gray-500 text-sm break-all mb-2">{{ leak.link }}</div>

                  <!-- Removal method indicator -->
                  <div class="flex items-center text-gray-500 text-sm mb-2">
                    <svg v-if="leak.search_engine" class="w-3.5 h-3.5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                      <circle cx="11" cy="11" r="8"/>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                    </svg>
                    <svg v-else class="w-3.5 h-3.5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                      <circle cx="12" cy="12" r="10"/>
                      <path d="M2 12h20"/>
                      <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/>
                    </svg>
                    {{ leak.search_engine ? trans('leakblock.lastLeaks.searchEngineSource') : trans('leakblock.lastLeaks.websiteSource') }}
                  </div>

                  <!-- Dates - Single line with scrolling -->
                  <div class="flex text-xs text-gray-500 mb-2 overflow-x-auto whitespace-nowrap pb-1">
                    <div class="flex-shrink-0 mr-4">
                      <span class="font-medium">{{ trans('leakblock.leakOverview.foundDate') }}:</span>
                      <span class="ml-1">{{ formatDate(leak.created_at) }}</span>
                    </div>
                    <div class="flex-shrink-0">
                      <span class="font-medium">{{ trans('leakblock.leakOverview.lastUpdated') }}:</span>
                      <span class="ml-1">{{ formatDate(leak.updated_at) }}</span>
                    </div>
                  </div>

                  <!-- Leak ID -->
                  <span class="text-xs text-gray-500 break-all block">ID: {{ leak.leak_id }}</span>
                </div>

                <!-- Arrow icon - Absolute positioned -->
                <div class="absolute bottom-4 right-4 text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </div>
            </router-link>
          </template>
        </div>
        <p v-else class="text-center text-gray-600">{{ trans('leakblock.leakOverview.noLeaksFound') }}</p>
      </div>
      <!-- Pagination section -->
      <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[60px] border-t border-gray-200 rounded-br-md px-6 items-center">
        <div class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500">
          {{ trans('leakblock.leakOverview.pageInfo', { current: currentPage, total: totalPages }) }}
        </div>
        <div class="flex-shrink-0 ml-auto">
          <router-link
              :to="{ name: 'leakOverview', params: { lang: $route.params.lang, accountUrl: $route.params.accountUrl, page: currentPage - 1 } }"
              v-if="currentPage > 1"
              class="mr-2 inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100"
          >
            {{ trans('leakblock.leakOverview.previous') }}
          </router-link>
          <router-link
              :to="{ name: 'leakOverview', params: { lang: $route.params.lang, accountUrl: $route.params.accountUrl, page: currentPage + 1 } }"
              v-if="currentPage < totalPages"
              class="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100"
          >
            {{ trans('leakblock.leakOverview.next') }}
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
// Script remains unchanged
import {ref, onMounted, watch, computed} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getLeaks } from '../../router/api';
import { trans } from "../../../../js/utilities/leakblock-translations";
import { formatDate } from '../../../../base';
import axios from "axios";

const props = defineProps({
  user: Object,
});

const isFreeTier = computed(() => props.user.isDemo);

const route = useRoute();
const router = useRouter();
const leaks = ref([]);
const isLoading = ref(true);
const currentPage = ref(parseInt(route.params.page) || 1);
const totalPages = ref(1);
const perPage = 20;

const fetchLeaks = async (page) => {
  isLoading.value = true;
  try {
    if (isFreeTier.value) {
      // For free tier, use the demo data
      const response = await axios.get('/build/demo/last-leaks.json');
      leaks.value = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      totalPages.value = 1; // Demo data is single page
      currentPage.value = 1;
    } else {
      // For paid tier, use real API
      const response = await getLeaks(route.params.accountUrl, page, perPage);
      leaks.value = response.data.leaks.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      totalPages.value = response.data.totalPages;
      currentPage.value = page;
    }
  } catch (error) {
    console.error('Error fetching leaks:', error);
  } finally {
    isLoading.value = false;
  }
};

watch(() => route.params, (newParams) => {
  const newPage = parseInt(newParams.page) || 1;
  if (newPage !== currentPage.value) {
    currentPage.value = newPage;
    fetchLeaks(newPage);
  }
}, {deep: true});

onMounted(() => {
  fetchLeaks(currentPage.value);
});
</script>