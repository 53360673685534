<template>
  <div class="container bg-gray-100 pt-[30px] lg:pt-[45px] pb-[30px]">
    <div class="max-w-4xl mx-auto">
      <!-- Navigation Tabs -->
      <div class="settings-tabs pt-6 mb-2 relative overflow-hidden flex items-start">
        <div class="swiper-container flex-grow overflow-hidden">
          <Swiper
              :modules="swiperModules"
              :slides-per-view="'auto'"
              :space-between="8"
              :free-mode="true"
              :mousewheel="true"
              class="settings-swiper m-0"
              @swiper="setSwiper"
          >
            <SwiperSlide
                v-for="tab in tabs"
                :key="tab.id"
                class="settings-slide"
                :class="{
                'pre-selected': isCurrentRoute(tab.route)
              }"
            >
              <div
                  @click="navigateTo(tab.route)"
                  :class="{
                  'bg-white border-b-4 border-primary-500': isCurrentRoute(tab.route),
                  'bg-white text-primary-500 hover:border-b-4 hover:border-primary-500': !isCurrentRoute(tab.route)
                }"
                  class="py-2 px-10 rounded-md text-center flex items-center lb-shadow cursor-pointer h-full"
              >
                {{ tab.name }}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="flex items-center ml-2">
          <button @click="handlePrevClick" class="navigation-button mr-1">
            <span class="sr-only">Previous</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button @click="handleNextClick" class="navigation-button mr-1">
            <span class="sr-only">Next</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Content Area -->
      <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in">
          <component :is="Component" v-bind="$route.params" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Mousewheel } from 'swiper/modules';
import { trans } from "../../../../js/utilities/leakblock-translations";
import 'swiper/css';
import 'swiper/css/free-mode';

const router = useRouter();
const route = useRoute();
const swiper = ref(null);
const swiperModules = [FreeMode, Mousewheel];

const tabs = [
  {
    id: 'subscription',
    name: trans('leakblock.subscription.tabs.management'),
    route: 'subscription-management'
  },
  {
    id: 'invoices',
    name: trans('leakblock.subscription.tabs.invoices'),
    route: 'subscription-invoices'
  }
];

const setSwiper = (swiperInstance) => {
  swiper.value = swiperInstance;
};

const currentTabIndex = computed(() => {
  return tabs.findIndex(tab => isCurrentRoute(tab.route));
});

const isCurrentRoute = (routeName) => {
  return route.name === routeName;
};

const navigateTo = (routeName) => {
  router.push({
    name: routeName,
    params: { lang: route.params.lang }
  });
};

const handlePrevClick = () => {
  if (currentTabIndex.value > 0) {
    navigateTo(tabs[currentTabIndex.value - 1].route);
    swiper.value?.slidePrev();
  }
};

const handleNextClick = () => {
  if (currentTabIndex.value < tabs.length - 1) {
    navigateTo(tabs[currentTabIndex.value + 1].route);
    swiper.value?.slideNext();
  }
};
</script>

<style scoped>
.settings-swiper {
  overflow: visible !important;
}

.settings-slide {
  width: auto;
}

.settings-swiper,
.settings-swiper :deep(.swiper-wrapper) {
  overflow: visible !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.settings-swiper::-webkit-scrollbar,
.settings-swiper :deep(.swiper-wrapper)::-webkit-scrollbar,
.settings-swiper :deep(.swiper-scrollbar) {
  display: none !important;
}

.navigation-button {
  @apply p-2 rounded-md bg-white text-gray-600 hover:bg-gray-50 lb-shadow;
}

.lb-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>