<template>
  <div class="bg-white rounded-lg shadow-sm">
    <div class="p-6">
      <h2 class="text-2xl font-semibold text-gray-700 mb-6">
        {{ trans('leakblock.subscription.invoices.title') }}
      </h2>
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>

      <div v-else-if="invoices.length" class="space-y-4">
        <div
            v-for="invoice in invoices"
            :key="invoice.id"
            class="bg-white border rounded-lg p-4 flex justify-between items-center"
        >
          <div class="space-y-1">
            <div class="font-medium">
              {{ trans('leakblock.subscription.invoices.number', { number: invoice.number }) }}
            </div>
            <div class="text-sm text-gray-500">
              {{ formatDate(invoice.created * 1000) }}
              - €{{ invoice.total.toFixed(2) }}
            </div>
            <div
                class="inline-block px-2 py-1 text-xs rounded-full"
                :class="{
                  'bg-green-100 text-green-800': invoice.status === 'paid',
                  'bg-yellow-100 text-yellow-800': invoice.status === 'open',
                  'bg-red-100 text-red-800': invoice.status === 'uncollectible'
                }"
            >
              {{ getInvoiceStatusText(invoice.status) }}
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <a
                v-if="invoice.pdf"
                :href="invoice.pdf"
                target="_blank"
                class="text-sm text-primary-500 hover:text-primary-600 underline"
            >
              {{ trans('leakblock.subscription.invoices.download') }}
            </a>
          </div>
        </div>
      </div>
      <div v-else class="text-gray-500 text-center py-4">
        {{ trans('leakblock.subscription.invoices.no_invoices') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { trans } from "../../../../js/utilities/leakblock-translations";
import { formatDate } from "../../../../base";

const invoices = ref([]);
const isLoading = ref(true);

onMounted(async () => {
  await fetchInvoices();
});

const fetchInvoices = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get('/api/subscription/invoices');
    invoices.value = response.data;
  } catch (error) {
    console.error('Error fetching invoices:', error);
  } finally {
    isLoading.value = false;
  }
};

const getInvoiceStatusText = (status) => {
  switch (status) {
    case 'paid':
      return trans('leakblock.subscription.invoices.status.paid');
    case 'open':
      return trans('leakblock.subscription.invoices.status.open');
    case 'uncollectible':
      return trans('leakblock.subscription.invoices.status.uncollectible');
    default:
      return status;
  }
};
</script>