<template>
  <div class="rounded-md lb-shadow xl:col-span-2 md:overflow-hidden relative">
    <h3 class="flex items-center text-lg font-semibold relative bg-white px-6 py-4 border-b border-gray-200">
      <button @click="goBack" class="mr-4 text-gray-600 hover:text-gray-900">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
        </svg>
      </button>
      {{ trans('leakblock.leakDetail.title') }}
    </h3>

    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
    </div>

    <div v-else-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-6" role="alert">
      <strong class="font-bold">{{ trans('leakblock.error.occurred') }}</strong>
      <span class="block sm:inline">{{ error }}</span>
    </div>

    <div v-else-if="leak" class="px-3 md:px-6 pt-3 pb-6">
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-8">
        <div class="intro-y">
          <div class="bg-white p-6 rounded-lg shadow-md">
            <div class="flex justify-between items-center mb-4">
              <div>
                <div class="flex items-center mb-1">
                  <img
                      :src="`https://www.google.com/s2/favicons?domain=${encodeURIComponent(leak.link)}`"
                      :alt="`${leak.website} favicon`"
                      class="w-4 h-4 mr-2 flex-shrink-0"
                      @error="handleFaviconError"
                  />
                  <h1 class="text-xl font-semibold">{{ leak.website }}</h1>
                </div>
                <div v-if="leak.search_engine" class="flex items-center mb-1 text-gray-500 text-sm">
                  {{ trans('leakblock.lastLeaks.foundThrough') }}
                  <div class="flex items-center">
                    <img
                        :src="leak.search_engine.favicon"
                        :alt="`${leak.website} favicon`"
                        class="w-4 h-4 ml-1 mr-0.5 flex-shrink-0"
                    />
                    {{ leak.search_engine.name }}
                  </div>
                </div>
                <a v-if="leak.search_engine_link" :href="leak.search_engine_link" target="_blank" class="text-sm text-gray-500 break-all">{{ leak.search_engine_link }}</a>
                <a v-else :href="leak.link" target="_blank" class="text-sm text-gray-500 break-all">{{ leak.link }}</a>
              </div>
            </div>
            <hr class="h-px my-4 2xl:mt-8 2xl:mb-4 bg-gray-200 border-0">
            <div class="py-3">
              <p>{{ trans('leakblock.leakDetail.id') }}: {{ leak.leak_id }}</p>
            </div>
            <div class="flex flex-col lg:flex-row xl:flex-col 2xl:flex-row mb-4">
              <div class="border-b lg:border-b-0 lg:border-r xl:border-b xl:border-r-0 2xl:border-b-0 2xl:border-r border-gray-200 lg:pr-8 xl:pr-0 2xl:pr-8 py-3">
                <h3 class="text-sm font-medium text-gray-500 mb-2">{{ trans('leakblock.leakDetail.status') }}</h3>
                <span class="bg-gray-100 text-gray-600 px-2 py-1 rounded-lg text-sm" :style="{ backgroundColor: leak.leak_status.bgColor, color: leak.leak_status.color }">
                  {{ trans(leak.leak_status.name) }}
                </span>
              </div>
              <div class="border-b lg:border-b-0 lg:border-r xl:border-b xl:border-r-0 2xl:border-b-0 2xl:border-r border-gray-200 lg:px-8 xl:px-0 2xl:px-8 py-3">
                <h3 class="text-sm font-medium text-gray-500 mb-2">{{ trans('leakblock.leakDetail.removalMethod') }}</h3>
                <span class="flex items-center text-gray-600">
                  <svg v-if="leak.search_engine" class="w-3.5 h-3.5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <circle cx="11" cy="11" r="8"/>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                  </svg>
                  <svg v-else class="w-3.5 h-3.5 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <circle cx="12" cy="12" r="10"/>
                    <path d="M2 12h20"/>
                    <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/>
                  </svg>
                  {{ leak.search_engine ? trans('leakblock.lastLeaks.searchEngineSource') : trans('leakblock.lastLeaks.websiteSource') }}
                </span>
              </div>
              <div class="border-b lg:border-b-0 lg:border-r xl:border-b xl:border-r-0 2xl:border-b-0 2xl:border-r border-gray-200 lg:px-8 xl:px-0 2xl:px-8 py-3">
                <h3 class="text-sm font-medium text-gray-500 mb-2">{{ trans('leakblock.leakDetail.foundDate') }}</h3>
                <p>{{ formatDate(leak.created_at) }}</p>
              </div>
              <div class="lg:px-8 xl:px-0 2xl:px-8 py-3">
                <h3 class="text-sm font-medium text-gray-500 mb-2">{{ trans('leakblock.leakDetail.lastUpdated') }}</h3>
                <p>{{ formatDate(leak.updated_at) }}</p>
              </div>
            </div>
            <div class="mt-10" v-if="leak.comment">
              <h3 class="text-sm font-medium text-gray-500 mb-2">{{ trans('leakblock.leakDetail.comment') }}</h3>
              <p>{{ leak.comment }}</p>
            </div>
            <div class="mt-10">
              <h3 class="text-sm font-medium text-gray-500 mb-2">{{ trans('leakblock.leakDetail.haveQuestions') }}</h3>
              <router-link
                  :to="{ name: 'messenger', query: { leakId: leak.leak_id }}"
                  class="flex inline-flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md font-medium"
              >
                <svg class="mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
                {{ trans('leakblock.leakDetail.chatAboutLeak') }}
              </router-link>
            </div>
          </div>
        </div>
        <CustomTimeline :items="leak.timeline" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { trans } from "../../../../js/utilities/leakblock-translations";
import { formatDate } from '../../../../base';
import CustomTimeline from "../../components/Timeline/CustomTimeline.vue";

const route = useRoute();
const router = useRouter();
const leak = ref(null);
const isLoading = ref(true);
const error = ref(null);

const fetchLeakDetails = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    // Check if it's a demo leak
    if (route.params.leakId.startsWith('DEMO_')) {
      const demoNumber = route.params.leakId.split('_')[1];
      const lang = route.params.lang || 'en';
      const response = await axios.get(`/build/demo/leak-detail-${demoNumber}-${lang}.json`);
      leak.value = response.data;
    } else {
      const response = await axios.get(`/api/leaks/${route.params.leakId}`);
      leak.value = response.data;
    }
  } catch (err) {
    console.error('Error fetching leak details:', err);
    error.value = 'Failed to load leak details. Please try again.';
  } finally {
    isLoading.value = false;
  }
};

const goBack = () => {
  router.push({ name: 'leakOverview', params: { lang: route.params.lang, accountUrl: route.params.accountUrl } });
};

onMounted(() => {
  fetchLeakDetails();
});
</script>