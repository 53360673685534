<template>
  <div class="max-w-4xl mx-auto">
    <div class="bg-white rounded-lg shadow-lg p-6 text-center">
      <h2 class="text-2xl font-bold text-green-600 mb-4">
        {{ trans('leakblock.subscription.success.title') }}
      </h2>
      <p class="text-gray-600 mb-4">
        {{ trans('leakblock.subscription.success.message') }}
      </p>
      <router-link
          :to="{ name: 'subscription-management', params: { lang: $route.params.lang }}"
          class="inline-block bg-primary-500 text-white px-6 py-2 rounded-lg"
      >
        {{ trans('leakblock.subscription.success.backToDashboard') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { trans } from '../../../../js/utilities/leakblock-translations';

const route = useRoute();

onMounted(async () => {
  const sessionId = route.query.session_id;
  if (sessionId) {
    try {
      await axios.post('/api/subscription/verify-session', { sessionId });
    } catch (error) {
      console.error('Error verifying session:', error);
    }
  }
});
</script>
