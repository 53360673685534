<template>
  <div class="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
    <template v-if="selectedAccount">
      <!-- Subscription Notice -->
      <div v-if="user?.isDemo" class="col-span-full">
        <div class="bg-gray-200 rounded-md p-6 border">
          <h3 class="text-lg font-semibold text-primary-500 mb-4">
            {{ trans('leakblock.accountDetail.subscriptionNoticeTitle') }}
          </h3>
          <p class="mb-2">
            {{ trans('leakblock.accountDetail.subscriptionNoticeText') }}
          </p>
          <div class="flex flex-col sm:flex-row gap-3 mt-4">
            <router-link
                :to="{ name: 'subscription-management', params: { lang: $route.params.lang }}"
                class="inline-block border bg-primary-500 border-primary-500 text-center text-white hover:bg-primary-400 px-10 py-2 transition rounded-md font-medium"
            >
              {{ trans('leakblock.accountDetail.goToPayment') }}
            </router-link>
            <a
                :href="trans('leakblock.accountDetail.consultationLink')"
                target="_blank"
                class="inline-block border border-primary-500 text-center text-primary-500 hover:bg-gray-300 px-10 py-2 transition rounded-md font-medium"
            >
              {{ trans('leakblock.accountDetail.subscriptionNoticeButtonBook') }}
            </a>
            <a
                href="https://wa.me/4915756168297"
                target="_blank"
                class="inline-block border border-primary-500 text-center text-primary-500 hover:bg-gray-300 px-10 py-2 transition rounded-md font-medium"
            >
              {{ trans('leakblock.accountDetail.subscriptionNoticeButtonWhatsapp') }}
            </a>
            <router-link
                :to="{
                  path: `/${currentLang}/messenger`,
                  query: { type: 'team' }
                }"
                class="inline-block border border-primary-500 text-center text-primary-500 hover:bg-gray-300 px-10 py-2 transition rounded-md font-medium"
            >
              {{ trans('leakblock.accountDetail.subscriptionNoticeButtonMessenger') }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-span-full grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        <LastLeaks :accountUrl="selectedAccount.account_url" class="component-height" :isFreeTier="isFreeTier" />
        <SelfReportedLastLeaks :accountUrl="selectedAccount.account_url" class="component-height" :isFreeTier="isFreeTier" />
        <LeakStatusStatistics :accountUrl="selectedAccount.account_url" class="component-height" :isFreeTier="isFreeTier" />
        <CompactChat class="component-height" />
        <RelatedAccountsOverview :accountUrl="selectedAccount.account_url" class="component-height" />
      </div>
    </template>
    <div v-else class="col-span-full">
      {{ trans('leakblock.accountDetail.loadingAccountDetails') }}
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from 'vue';
import LastLeaks from "../../components/Leaks/LastLeaks.vue";
import SelfReportedLastLeaks from "../../components/Leaks/SelfReportedLastLeaks.vue";
import LeakStatusStatistics from "../../components/Leaks/LeakStatusStatistics.vue";
import CompactChat from "../../components/Chat/CompactChat.vue";
import RelatedAccountsOverview from "../../components/Account/RelatedAccountsOverview.vue";
import { trans } from "../../../../js/utilities/leakblock-translations";
import { useRoute } from "vue-router";

const route = useRoute();
const isFreeTier = computed(() => props.user.isDemo);

const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  selectedAccount: {
    type: Object,
    default: () => ({})
  }
});

const currentLang = ref(route.params.lang || 'en');
</script>
