import './bootstrap.js';
import './styles/app.css';
import './scripts/charts';
import 'flowbite/dist/flowbite.js';
import moment from "moment/moment";
import {registerVueControllerComponents} from "@symfony/ux-vue";


registerVueControllerComponents(require.context('./vue/user/controllers', true, /\.vue$/));

export function formatDate(date) {
    if (!date) return '';

    try {
        const formattedDate = typeof date === 'string'
            ? new Date(date)
            : typeof date === 'number'
                ? (date.toString().length === 10 ? new Date(date * 1000) : new Date(date))
                : new Date(date);

        return formattedDate.toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    } catch (error) {
        console.error('Date formatting error:', error, date);
        return '';
    }
}